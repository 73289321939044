  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/1-5-1-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li><a href='/expertise-construction/expertise-batiment/'>Expertise technique batiment</a></li><li>Malfaçon</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Les malfaçons, cauchemar du Maître d’ouvrage</h1>

<p>Une malfaçon est une exécution défectueuse d'un travail, c'est-à-dire qui présente un défaut.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Malfaçon" />
</div>


<p>C'est souvent dans le secteur de la construction ou de la rénovation que l'on constate des malfaçons, comme par exemple des problèmes d'étanchéité ou des fissures, pose d’équipements défectueux.</p>
<p>La difficulté : ces malfaçons n'apparaissent pas toujours avant la signature de la réception des travaux.</p>

<p>Bien souvent, le constat de malfaçons implique un litige avec l'exécutant. </p>
<p>Dans le meilleur des cas, l’entreprise exécutante viendra corriger les problèmes. </p>
<p>Dans le cas inverse, il vous est souvent impossible de vous faire indemniser ou que l’entreprise intervienne en réparation. </p>

<p>Des recours existent et faire appel à un expert technique indépendant vous permet de défendre vos droits et intérêts et de résoudre le problème. </p>

<h2>Que faire en cas de malfaçons ?   </h2>

<p>Lorsque vous constatez une malfaçon sur votre ouvrage, vous avez la possibilité de faire recourir à la garantie décennale du constructeur, du moment que la malfaçon ou le désordre soit reconnu.. par le constructeur ou par l’Expert d’assurance de la décennale du constructeur ! </p>

<p>Ce qui n’est pas toujours le cas ! Bien souvent, soit l’entreprise ne répond plus à vos sollicitations, soit ne reconnaît pas les malfaçons, soit l’Expert de la décennale minimise les défauts. </p>

<p>Vous êtes finalement démuni face à un bien comportant des malfaçons, des désordres esthétiques.</p>

<p>Afin de rétablir vos droits et de trouver un accord avec l’entreprise responsable des travaux, vous avez la possibilité de mandater votre Expert indépendant, qui interviendra afin de défendre vos intérêts.</p>

<h2>Pourquoi faire appel à un Expert indépendant ?   </h2>

<p>L’Expert pour sinistre malfaçons / désordres intervient dans une optique de règlement amiable du conflit.</p>

<p>Tout d’abord, l’Expert intervient sur site afin de réaliser un rapport détaillé des malfaçons constatées et vous orienter par la suite sur les recours possibles :</p>
<ul><li>Il vérifie la conformité des travaux,</li>
<li>entre en contact avec l’entreprise et son assurance décennale,</li>
<li>négocie avec l’assurance la juste indemnisation. </li></ul>

<p>Sa force de proposition permet généralement une conciliation à l’amiable de litiges. </p>

<h2>Nos expertises et prestations </h2>

<p>Lors de nos expertises, notre expertise technique permet de démontrer si malfaçons il y a et quelles sont les responsabilités engagées.</p>
<p> </p>
<p>Suivant le contexte d’étude, nous vérifions en tout premier lieu :</p>

<ul><li>le contrat établi entre vous et l’entreprise, artisan ou le professionnel engagé,</li>
<li>nous vérifions son assurance professionnel,</li>
<li>nous effectuons toujours une visite technique in situ,</li>
<li>tous désordres et malfaçons sont répertoriés dans un rapport,</li>
<li>Nous nous référons au DTU s’appliquant au domaine de l’Art,</li>
<li>Nous échangeons avec l’Entreprise concernée et/ou l’Expert d’assurance dans une optique de résolution à l’amiable. </li></ul>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/expertise-batiment/malfacons/' className='active'>Malfaçon</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details